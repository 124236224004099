

<template>
  <div :class='valueClass'>{{ label }}</div>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';

export default Vue.extend({
  name: 'StaffCountCellRenderer',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {
    this.value = this.params? this.params.value : null;
    setReadOnlyIfNotEditable(this.params);
  },
  computed: {
    valueClass() {
      return 'normal';
    },
    label() {
      var value = 0;
      if (this.params.data && this.params.data.staffList != null) {
        for (const stf of this.params.data.staffList) {
          if (this.params.realStaff && !stf.genericStaff) {
            value += 1;
          }
          else if (this.params.genericStaff && stf.genericStaff) {
            value += stf.resourceQuota;
          }
        }
      }
      
      const node = this.params.node;
      if (typeof node.group !== 'undefined') {
        for (var j = 0; j < node.group.length; j++) {
          const childNode = node.group[j];
          if (typeof childNode.staffList !== 'undefined') {
            for (const stf of childNode.staffList) {
              if (this.params.realStaff && !stf.genericStaff) {
                value += 1;
              }
              else if (this.params.genericStaff && stf.genericStaff) {
                value += stf.resourceQuota;
              }
            }
          }
        }
      }      
      return value !== 0 ? value : '';
    }
  }
})
</script>

<style lang="scss" scoped>
.normal {
  width: 24px;
  height: 24px;
  text-align: left;
  // margin: auto;
}

</style>